// /cms/schoolbook/book/list
import request from "@/core/services/axios";

export function booklist(data) {
  return request({
    url: "/cms/schoolbook/book/list",
    method: "get",
    params: data,
  });
}

export function bookSave(data) {
  return request({
    url: "/cms/schoolbook/book/save",
    method: "post",
    data: data,
  });
}
